// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-work-index-jsx": () => import("./../../../src/pages/our-work/index.jsx" /* webpackChunkName: "component---src-pages-our-work-index-jsx" */),
  "component---src-pages-technologies-jsx": () => import("./../../../src/pages/technologies.jsx" /* webpackChunkName: "component---src-pages-technologies-jsx" */),
  "component---src-pages-website-questionnaire-jsx": () => import("./../../../src/pages/website-questionnaire.jsx" /* webpackChunkName: "component---src-pages-website-questionnaire-jsx" */)
}

